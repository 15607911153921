<template>
  <div class="childrenComponent">

    <b-modal ref="errorChildAPILoadingErrorModal" id="errorChildAPILoadingErrorModal" hide-footer title="Erreur de communication avec le serveur">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez vérifier vos informations et réessayer.</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('errorChildAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>


    <b-modal ref="modifyChildModal" id="modifyChildModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Modifier votre enfant </strong>
      </template>
      <div>
        <p>
            Attention, si vous modifiez votre enfant alors qu'une demande de remboursement est en cours pour celui-ci,
            la modification sera également répercutée sur la demande de remboursement.
        </p>
        <b-form>
        <div class="row">
          <div class="col-sm-6">
            <b-form-group id="modify-child-first_name-group" label="Prénom" label-for="modify-child-first_name-input" class="mandatory-input">
              <b-form-input
                v-model="modifyChildFirstName" type="text"
                maxlength="29"
                placeholder="Prénom"
                id="modify-child-first_name-input"
                @input="$v.modifyChildFirstName.$touch()"
                :state="$v.modifyChildFirstName.$dirty ? !$v.modifyChildFirstName.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.modifyChildFirstName.$error">Le prénom doit comporter entre 2 et 30 caractères</div>
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group id="modify-child-last_name-group" label="Nom" label-for="modify-child-last_name-input" class="mandatory-input">
              <b-form-input
                v-model="modifyChildLastName" type="text"
                maxlength="149"
                placeholder="Nom"
                id="modify-child-last_name-input"
                @input="$v.modifyChildLastName.$touch()"
                :state="$v.modifyChildLastName.$dirty ? !$v.modifyChildLastName.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.modifyChildLastName.$error">Le nom doit comporter entre 2 et 150 caractères</div>
            </b-form-group>
          </div>
        </div>

        <div v-if="$browserDetect.isSafari">
          <b-form-group id="modify-child-birthdate-group" label="Date de naissance" label-for="modify-child-birthdate-input" class="mandatory-input">
            <b-form-datepicker
              id="modify-child-birthdate-input"
              name="modify-child-birthdate-input"
              locale="fr"
              :max="maxNewChildBirthdate"
              :show-decade-nav="true"
              placeholder="Choisissez une date"
              v-model="modifyChildBirthdate" class="mb-2"
              @input="$v.modifyChildBirthdate.$touch()"
              :state="$v.modifyChildBirthdate.$dirty ? !$v.modifyChildBirthdate.$error : null">
            </b-form-datepicker>
            <small class="text-danger" v-if="!$v.modifyChildBirthdate.validDate">Veuillez entrer une date antérieure à aujourd'hui et au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
          </b-form-group>
        </div>
        <div v-else>
          <b-form-group id="modify-child-birthdate-group" label="Date de naissance" label-for="modify-child-birthdate-input" class="mandatory-input">
            <b-form-input
              v-model.trim.lazy="modifyChildBirthdate" type="date"
              id="modify-child-birthdate-group"
              placeholder="AAAA-MM-DD"
              @input="$v.modifyChildBirthdate.$touch()"
              :state="$v.modifyChildBirthdate.$dirty ? !$v.modifyChildBirthdate.$error : null">
            </b-form-input>
            <div v-if="$browserDetect.isIE" class="text-info">
              <small>Vous utilisez Internet Explorer, veuillez entrer votre
              date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
            </div>
            <div class="form-error" v-if="!$v.modifyChildBirthdate.validDate">Veuillez entrer une date antérieure à aujourd'hui et au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
          </b-form-group>
        </div>

        <b-button
          @click="modifyChild()" pill block :variant="modifyChildButtonStyle" class="mt-2"
          :disabled="$v.modifyChildFirstName.$invalid || $v.modifyChildLastName.$invalid || $v.modifyChildBirthdate.$invalid || this.modifyChildInProcess">
            <b-spinner small label="Modify Beneficiaire Spinner" v-if="modifyChildInProcess"></b-spinner> {{ modifyChildButtonText }}
        </b-button>
        </b-form>
      </div>
    </b-modal>


    <b-modal v-model="isChildDuplicate" ref="duplicateChildModal" id="duplicateChildModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Attention, risque de doublons </strong>
      </template>
      <div>
        <p>
            <strong>Un enfant avec ce prénom, nom et date de naissance a déjà été enregistré</strong>. Veuillez vérifier qu'il ne s'agit pas d'un doublon
            ou que vous n'avez pas déjà enregistré votre enfant sous un autre compte.
        </p>
        <p>
          Dans le cas contraire, cliquer sur le bouton ci-dessous pour ajouter votre enfant
        </p>
        <b-button
          @click="addChild()" :disabled="addChildInProcess" pill block variant="outline-danger" class="mt-2">
            Ajouter votre enfant
        </b-button>
      </div>
    </b-modal>


    <b-table
      responsive class="align-middle text-center mt-4"
      headVariant="light"
      :fields="childrenRenderFields"
      :items="childrenRender"
      >
      <template v-slot:cell(birthdate)="data">
        {{$dayjs(data.item.birthdate).format('D MMM YYYY')}}
      </template>
      <template v-slot:cell(age)="data">
        <span v-if="data.item.age >= 4" class="text-danger">{{data.item.age}} ans <small> (non éligibile) </small> </span>
        <span v-else class="text-success">{{data.item.age}} ans <small> (éligible)</small></span>
      </template>
      <template v-slot:cell(actions)="data">
        <b-button
          variant="outline-primary" class="ml-2" size="sm"
          @click='showModifyChildModal(data.item)'>
          <v-icon name="edit"/>
        </b-button>
        <b-button
          variant="outline-danger" class="ml-2" size="sm"
          @click='deleteChild(data.item.id)'>
          <v-icon name="trash-alt"/>
        </b-button>
      </template>
    </b-table>

    <div v-if="children.length <= 50" class="row">
      <div class="col-12">
          <b-button v-b-toggle.add-child-collapse variant="outline-secondary" pill block>
            <span class="when-closed">Ajouter un enfant</span>
            <span class="when-open">Annuler</span>
          </b-button>
      </div>
    </div>

    <b-collapse id="add-child-collapse" class="mt-2">
      <b-form v-on:submit.prevent="checkChildDuplicate()">
        <div class="row">
          <div class="col-sm-6">
            <b-form-group id="new-child-first_name-group" label="Prénom" label-for="new-child-first_name-input" class="mandatory-input">
              <b-form-input
                v-model="newChildFirstName" type="text"
                maxlength="29"
                placeholder="Prénom"
                id="new-child-first_name-input"
                @input="$v.newChildFirstName.$touch()"
                :state="$v.newChildFirstName.$dirty ? !$v.newChildFirstName.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.newChildFirstName.$error">Le prénom doit comporter entre 2 et 30 caractères</div>
            </b-form-group>
          </div>
          <div class="col-sm-6">
            <b-form-group id="new-child-last_name-group" label="Nom" label-for="new-child-last_name-input" class="mandatory-input">
              <b-form-input
                v-model="newChildLastName" type="text"
                maxlength="149"
                placeholder="Nom"
                id="new-child-last_name-input"
                @input="$v.newChildLastName.$touch()"
                :state="$v.newChildLastName.$dirty ? !$v.newChildLastName.$error : null">
              </b-form-input>
              <div class="form-error" v-if="$v.newChildLastName.$error">Le nom doit comporter entre 2 et 150 caractères</div>
            </b-form-group>
          </div>
        </div>

        <div v-if="$browserDetect.isSafari">
          <b-form-group id="new-child-birthdate-group" label="Date de naissance" label-for="new-child-birthdate-input" class="mandatory-input">
            <b-form-datepicker
              id="new-child-birthdate-input"
              name="new-child-birthdate-input"
              locale="fr"
              :max="maxNewChildBirthdate"
              :show-decade-nav="true"
              placeholder="Choisissez une date"
              v-model="newChildBirthdate" class="mb-2"
              @input="$v.newChildBirthdate.$touch()"
              :state="$v.newChildBirthdate.$dirty ? !$v.newChildBirthdate.$error : null">
            </b-form-datepicker>
            <small class="text-danger" v-if="!$v.newChildBirthdate.validDate">Veuillez entrer une date antérieure à aujourd'hui et au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
          </b-form-group>
        </div>
        <div v-else>
          <b-form-group id="new-child-birthdate-group" label="Date de naissance" label-for="new-child-birthdate-input" class="mandatory-input">
            <b-form-input
              v-model.trim.lazy="newChildBirthdate" type="date"
              id="new-child-birthdate-group"
              placeholder="AAAA-MM-DD"
              @input="$v.newChildBirthdate.$touch()"
              :state="$v.newChildBirthdate.$dirty ? !$v.newChildBirthdate.$error : null">
            </b-form-input>
            <div v-if="$browserDetect.isIE" class="text-info">
              <small>Vous utilisez Internet Explorer, veuillez entrer votre
              date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
            </div>
            <div class="form-error" v-if="!$v.newChildBirthdate.validDate">Veuillez entrer une date antérieure à aujourd'hui et au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
          </b-form-group>
        </div>

        <b-button
          type="submit" pill block variant="outline-info" class="mt-2"
          :disabled="$v.newChildFirstName.$invalid || $v.newChildLastName.$invalid || $v.newChildBirthdate.$invalid || this.addChildInProcess">
            Ajouter votre enfant
        </b-button>
      </b-form>
    </b-collapse>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'
import { isDateLessThanToday } from '@/validators/validators'
import { dateOperations } from '@/mixins/utilMixins'

import { APIBeneficiaireConnected } from '@/api/APIBeneficiaireConnected'

const apiBeneficiaireConnectedService = new APIBeneficiaireConnected()

export default {
  name: 'childrenComponent',
  props: {
    children: Array
  },
  mixins: [
    validationMixin,
    dateOperations
  ],
  data: function () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {

      childrenRenderFields: [
        { key: "first_name", label: "Prénom"},
        { key: "last_name", label: "Nom"},
        { key: "birthdate", label: "Date de naissance"},
        { key: "age", label: "Age"},
        { key: "actions", label: "Actions"},
      ],

      // ----------- ADD CHILD -----------------

      newChildFirstName: '',
      newChildLastName: '',
      newChildBirthdate: '',
      maxNewChildBirthdate: today,

      addChildInProcess: false,

      // ---------- MODIFY ----------------------
      modifyChildId: '',

      modifyChildFirstName: '',
      modifyChildLastName: '',
      modifyChildBirthdate: '',

      modifyChildButtonText: 'Modifier votre enfant',
      modifyChildButtonStyle: 'outline-danger',
      modifyChildInProcess: false,

      // --- DUPLICATES
      isChildDuplicate: false

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    childrenRender () {
      if (this.children.length == 0) {
        return []
      }
      else {
        var final_array = []
        for (var i = 0; i < this.children.length; i++) {
          if (this.children[i].is_active) {
            final_array.push(
              {
                'id': this.children[i].id,
                'first_name': this.children[i].first_name,
                'last_name': this.children[i].last_name,
                'birthdate': this.children[i].birthdate,
                'age': this.calculateAge(this.children[i].birthdate),
              }
            )
          }
        }
        return final_array
      }
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
  },
  validations: {
    newChildFirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50)
    },
    newChildLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    newChildBirthdate: {
      required,
      validDate(value) {
        return isDateLessThanToday(value)
      },
    },

    modifyChildFirstName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50)
    },
    modifyChildLastName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    modifyChildBirthdate: {
      required,
      validDate(value) {
        return isDateLessThanToday(value)
      },
    },
  },
  methods: {
    addChild () {
      this.addChildInProcess = true
      apiBeneficiaireConnectedService.addChild(this.token, this.newChildFirstName,
        this.newChildLastName, this.newChildBirthdate)
      .then(() => {
        this.$root.$emit('bv::toggle::collapse', 'add-child-collapse')
        this.updateChildrenList()
        this.resetNewChild()
      })
      .catch(() => {
        this.$refs.errorChildAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.addChildInProcess = false
        this.isChildDuplicate = false
      })
    },

    showModifyChildModal (child) {
      this.modifyChildId = child.id
      this.modifyChildFirstName = child.first_name
      this.modifyChildLastName = child.last_name
      this.modifyChildBirthdate = child.birthdate
      this.$refs.modifyChildModal.show()
    },


    modifyChild () {
      this.modifyChildInProcess = true
      apiBeneficiaireConnectedService.modifyChild(this.token, this.modifyChildId, this.modifyChildFirstName,
        this.modifyChildLastName, this.modifyChildBirthdate)
      .then(() => {
        this.updateChildrenList()
      })
      .catch(() => {
        this.$refs.errorChildAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$refs.modifyChildModal.hide()
        this.modifyChildButtonText = "Modifier votre enfant"
        this.modifyChildButtonStyle = "outline-danger"
        this.modifyChildInProcess = false
      })
    },

    deleteChild (child_pk) {
      apiBeneficiaireConnectedService.deleteChild(this.token, child_pk)
      .then(() => {
        this.updateChildrenList()
      })
      .catch(() => {
        this.$refs.errorChildAPILoadingErrorModal.show()
      })
    },

    checkChildDuplicate () {
      apiBeneficiaireConnectedService.childDuplicate(this.token, this.newChildFirstName, this.newChildLastName, this.newChildBirthdate)
      .then((response) => {
        if (response.data.duplicates > 0) {
          this.isChildDuplicate = true
        }
        else {
          this.isChildDuplicate = false
          this.addChild()
        }
      })
      .catch(() => {
        this.$refs.errorChildAPILoadingErrorModal.show()
      })
    },

    updateChildrenList () {
      this.$emit('updateChildrenList')
    },


    resetNewChild () {
      this.newChildFirstName = ''
      this.newChildLastName = ''
      this.newChildBirthdate = ''
    },
  }

}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
